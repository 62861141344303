import {makeAutoObservable} from "mobx";
import {
  applyDiscountLineItem,
  fetchAddItem,
  fetchCart,
  fetchRemoveItem,
  fetchUpdateItem,
} from "../services/cart";

class Cart {
  data = {};

  constructor() {
    makeAutoObservable(this);
    this.initCart = this.initCart.bind(this);
    this.reloadCart = this.reloadCart.bind(this);
    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  get itemCount() {
    return this.data.item_count ?? 0;
  }

  get items() {
    return this.data.items ?? [];
  }

  get subtotalPrice() {
    return this.data.total_price ?? 0;
  }

  get totalPrice() {
    return this.data.original_total_price ?? 0;
  }

  get totalDiscount() {
    return this.data.total_discount ?? 0;
  }

  get cartDiscountCodes() {
    return this.data.cart_level_discount_applications.reduce(
      (acc, next) =>
        next.type === "discount_code" ? `${acc} ${next.title}` : acc,
      "",
    );
  }

  setCartData(data) {
    this.data = data;
  }

  async initCart() {
    const data = await fetchCart();

    this.setCartData(data);

    return data;
  }

  async reloadCart() {
    const data = await fetchCart();

    this.setCartData(data);

    window.dispatchEvent(
      new CustomEvent("cart:reload", {
        detail: {cart: data},
      }),
    );

    return data;
  }

  async addItem(payload) {
    return await fetchAddItem(payload);
  }

  async removeItem(payload) {
    return await fetchRemoveItem(payload);
  }

  async updateItem(payload) {
    return await fetchUpdateItem(payload);
  }

  async applyDiscountLineItem(payload) {
    return await applyDiscountLineItem(payload);
  }
}

export const cart = new Cart();
